import React from 'react';
import { Admin, Resource } from 'react-admin';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import authProvider from './AuthProvider';
import './App.css';
import { UserList, UserEdit, UserCreate } from './components/Users';
import dtProvider from './utils/dataProvider';
import {
  UserSubscriptionList,
  UserSubscriptionEdit,
  UserSubscriptionCreate,
} from './components/UserSubscription';
import {
  SubscriptionPlanList,
  SubscriptionPlanEdit,
  SubscriptionPlanCreate,
} from './components/SubscriptionPlan';
// import { UserCount } from './components/UserCount';
import { PaymentList } from './components/Payment';
import { CouponCreate, CouponEdit, CouponList, BulkCouponCreate } from './components/Coupon';
// import { PaymentLogList } from './components/PaymentLog';
import { AdminUserList, AdminUserEdit, AdminUserCreate } from './components/AdminUser';
import Layout from './components/TomYoLayout';
import i18nProvider from './utils/i18n/i18nProvider';

// Zodiacs
import {
  SignList,
  SignCreate,
  SignEdit,
} from './components/Sign';
import {
  ZTagList,
  ZTagCreate,
  ZTagEdit,
} from './components/ZTag';
import {
  MoodList,
  MoodCreate,
  MoodEdit,
} from './components/Mood';
import {
  MonthlyTypeList,
  MonthlyTypeCreate,
  MonthlyTypeEdit,
} from './components/MonthlyType';
import {
  AboutList,
  AboutCreate,
  AboutEdit,
} from './components/About';
import {
  DailyList,
  DailyCreate,
  DailyEdit,
} from './components/Daily';
import {
  WeeklyList,
  WeeklyCreate,
  WeeklyEdit,
} from './components/Weekly';
import {
  MonthlyList,
  MonthlyCreate,
  MonthlyEdit,
} from './components/Monthly';
import {
  MonthlyStatusList,
  MonthlyStatusCreate,
  MonthlyStatusEdit,
} from './components/MonthlyStatus';
import {
  YearlyList,
  YearlyCreate,
  YearlyEdit,
} from './components/Yearly';
import {
  CompatibilityList,
  CompatibilityCreate,
  CompatibilityEdit,
} from './components/Compatibility';
import {
  MoonCalendarList,
  MoonCalendarCreate,
  MoonCalendarEdit,
} from './components/MoonCalendar';
import {
  MoonCalendarImagesList,
  MoonCalendarImagesCreate,
  MoonCalendarImagesEdit,
} from './components/MoonCalendarImages';
import {
  MoonCalendarTypesList,
  MoonCalendarTypesCreate,
  MoonCalendarTypesEdit,
} from './components/MoonCalendarTypes';
// Banner
import {
  ExploreBannerList,
  ExploreBannerCreate,
  ExploreBannerEdit
} from './components/ExploreBanner';
import { FeedbackList } from "./components/Feedback";
// import {ArticleCreate, ArticleEdit, ArticleList} from "./components/Article";
import {
  DeploymentConfigList,
  DeploymentConfigEdit,
  DeploymentConfigCreate,
} from './components/DeploymentConfig';

function App() {
  return (
    <Admin layout={Layout} dataProvider={dtProvider} authProvider={authProvider} i18nProvider={i18nProvider}>
      {permissions => [
        <Resource
          name="general"
          icon={ArrowForwardIosIcon}
          options={{ label: 'Ерөнхий', isMenuParent: true }}
        />,
        <Resource
          name="signs"
          options={{ label: 'Ордууд', menuParent: 'general' }}
          list={SignList}
          edit={SignEdit}
          create={SignCreate}
        />,
        <Resource
          name="ztags"
          options={{ label: 'Төрлүүд', menuParent: 'general' }}
          list={ZTagList}
          edit={ZTagEdit}
          create={ZTagCreate}
        />,
        <Resource
          name="moods"
          options={{ label: 'Дэд төрлүүд', menuParent: 'general' }}
          list={MoodList}
          edit={MoodEdit}
          create={MoodCreate}
        />,
        <Resource
          name="monthlyTypes"
          options={{ label: 'Сарын төлөвүүд', menuParent: 'general' }}
          list={MonthlyTypeList}
          edit={MonthlyTypeEdit}
          create={MonthlyTypeCreate}
        />,
        <Resource
          name="banners"
          options={{ label: 'Баннер', menuParent: 'general' }}
          list={ExploreBannerList}
          edit={ExploreBannerEdit}
          create={ExploreBannerCreate}
        />,
        <Resource
          name="feedbacks"
          options={{ label: 'Санал хүсэлт', menuParent: 'general' }}
          list={FeedbackList}
        />,
        <Resource
          name="user"
          icon={ArrowForwardIosIcon}
          options={{ label: 'Хэрэглэгч', isMenuParent: true }}
        />,
        <Resource
          name="users"
          options={{ label: 'Хэрэглэгч', menuParent: 'user' }}
          list={UserList}
          edit={UserEdit}
          create={UserCreate}
        />,
        <Resource
          name="adminUsers"
          options={{ label: 'Админ хэрэглэгч', menuParent: 'user' }}
          list={AdminUserList}
          edit={AdminUserEdit}
          create={AdminUserCreate}
        />,
        // <Resource
        //   name="sales"
        //   icon={ArrowForwardIosIcon}
        //   options={{ label: 'Захиалга', isMenuParent: true }}
        // />,
        <Resource
          name="userSubscriptions"
          options={{ label: 'Хэрэглэгчийн төлбөр', menuParent: "user" }}
          list={UserSubscriptionList}
          edit={UserSubscriptionEdit}
          create={UserSubscriptionCreate}
        />,
        <Resource
          name="coupons"
          options={{ label: 'Купон', "menuParent": "user" }}
          list={CouponList}
          edit={CouponEdit}
          create={CouponCreate}
        />,
        <Resource
          name="bulk/coupons"
          options={{ label: 'Олон купон үүсгэх', menuParent: "sales" }}
          create={BulkCouponCreate}
        />,
        <Resource
          name="subscriptionPlans"
          options={{ label: 'Төлбөрийн төрөл', menuParent: "user" }}
          list={SubscriptionPlanList}
          edit={SubscriptionPlanEdit}
          create={SubscriptionPlanCreate}
        />,
        <Resource
          name="payments"
          options={{ label: 'Төлбөрийн гүйлгээ', menuParent: "user" }}
          list={PaymentList}
        />,
        <Resource
          name="zodiacs"
          icon={ArrowForwardIosIcon}
          options={{ label: 'Зурхай', isMenuParent: true }}
        />,
        <Resource
          name="zodiac/abouts"
          options={{ label: 'Ерөнхий зурхай', menuParent: "zodiacs" }}
          list={AboutList}
          create={AboutCreate}
          edit={AboutEdit}
        />,
        <Resource
          name="zodiac/daily"
          options={{ label: 'Өдрийн зурхай', menuParent: "zodiacs" }}
          list={DailyList}
          create={DailyCreate}
          edit={DailyEdit}
        />,
        <Resource
          name="zodiac/weekly"
          options={{ label: '7 хоногийн зурхай', menuParent: "zodiacs" }}
          list={WeeklyList}
          create={WeeklyCreate}
          edit={WeeklyEdit}
        />,
        <Resource
          name="zodiac/monthly"
          options={{ label: 'Сарын зурхай', menuParent: "zodiacs" }}
          list={MonthlyList}
          create={MonthlyCreate}
          edit={MonthlyEdit}
        />,
        <Resource
          name="zodiac/monthlyStatus"
          options={{ label: 'Сарын төлөв', menuParent: "zodiacs" }}
          list={MonthlyStatusList}
          create={MonthlyStatusCreate}
          edit={MonthlyStatusEdit}
        />,
        <Resource
          name="zodiac/yearly"
          options={{ label: 'Жилийн зурхай', menuParent: "zodiacs" }}
          list={YearlyList}
          create={YearlyCreate}
          edit={YearlyEdit}
        />,
        <Resource
          name="zodiac/compatibility"
          options={{ label: 'Ордуудын зохицол', menuParent: "zodiacs" }}
          list={CompatibilityList}
          create={CompatibilityCreate}
          edit={CompatibilityEdit}
        />,
        <Resource
          name="mooncalendar"
          icon={ArrowForwardIosIcon}
          options={{ label: 'Сарны хуанли', isMenuParent: true }}
        />,
        <Resource
          name="moonCalendars"
          options={{ label: 'Сарны хуанли', menuParent: "mooncalendar" }}
          list={MoonCalendarList}
          create={MoonCalendarCreate}
          edit={MoonCalendarEdit}
        />,
        <Resource
          name="moonCalendarImages"
          options={{ label: 'Зурагнууд', menuParent: "mooncalendar" }}
          list={MoonCalendarImagesList}
          create={MoonCalendarImagesCreate}
          edit={MoonCalendarImagesEdit}
        />,
        <Resource
          name="moonCalendarTypes"
          options={{ label: 'Төрлүүд', menuParent: "mooncalendar" }}
          list={MoonCalendarTypesList}
          create={MoonCalendarTypesCreate}
          edit={MoonCalendarTypesEdit}
        />,
        <Resource
          name="settings"
          icon={ArrowForwardIosIcon}
          options={{ label: 'Тохиргоо', isMenuParent: true }}
        />,
        <Resource
          name="deploymentConfigs"
          options={{ label: 'Deployment Config', menuParent: 'settings' }}
          list={DeploymentConfigList}
          edit={DeploymentConfigEdit}
          create={DeploymentConfigCreate}
        />,
      ]}
    </Admin>
  );
}

export default App;
